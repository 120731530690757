import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { theme } from '@ecp/common/src/style/theme/default';
import { FlexBox } from '@ecp/common/src/layouts/flex/styled';
import Badge from '@ecp/common/src/components/badge/Badge';
import { TextBox } from '@ecp/common/src/text/TextBox';

const GnbIconButton2 = React.forwardRef(
  ({ iconType: Icon, badgeContent, label, selected, to, showZero, iconCount, unit, ...props }, ref) => {
    const color = useMemo(
      () => ({
        icon: selected ? theme.color.icon.current : theme.color.icon.normal,
        label: selected ? theme.color.primary : theme.color.text.normal,
      }),
      [selected]
    );
    return (
      <Link style={{ textDecoration: 'none' }} to={to} {...props}>
        <Wrapper ref={ref}>
          <Badge
            badgeContent={badgeContent}
            position={{ top: -8, right: -8 }}
            size={{ width: '16px', height: '16px' }}
            showZero={showZero}
          >
            <Icon width={'20px'} height={'20px'} />
          </Badge>
          <Label color={color.label}>{label}</Label>
          <TextBox>{iconCount} {unit}</TextBox>
        </Wrapper>
      </Link>
    );
  }
);
export default GnbIconButton2;

GnbIconButton2.propTypes = {
  iconType: PropTypes.elementType,
  badgeContent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  selected: PropTypes.bool,
  showZero: PropTypes.bool,
  to: PropTypes.string,
  iconCount:PropTypes.number,
  unit:PropTypes.string,
};

GnbIconButton2.defaultProps = {
  selected: false,
  showZero: false,
};

GnbIconButton2.displayName = 'GnbIconButton2';

const Wrapper = styled(FlexBox)`
  width: fit-content;
  height: 36px;
  gap: 3px;
  cursor: pointer;
`;

const Label = styled.span.attrs(({ color, style }) => ({ style: { ...style, color } }))`
  font-size: 11px;
  line-height: 11px;
  font-weight: ${theme.font.weight.demiLight};
`;
