import React, { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import PropTypes from 'prop-types';

import { userInfoState } from '@fo-recoil/common/user/atom';
import { withCartBadgeCount, withCouponCount, withPoint } from '@fo-recoil/order/selector';
import { getIsPointUse, getIsEventMall, getIsCouponMenuUse } from '@fo-recoil/system/selector';
import myAssetPointApi from '@fo-apis/myPage/myAssetPointApi';
import couponApi from '@fo-apis/coupon/couponApi';
// import useSiteInfo from '@fo-hooks/common/useSiteInfo';
import authApi from '@fo-apis/common/authApi';
import orderApi from '@fo-apis/order/orderApi';

const UserInfoProvider = ({ children }) => {
  const setMemberInfo = useSetRecoilState(userInfoState);
  

  useEffect(() => {
    (async () => {
      const { result } = await authApi.info();
      setMemberInfo(result);
    })();
  }, [setMemberInfo]);

  const setCartBadgeCount = useSetRecoilState(withCartBadgeCount);
  const setPoint = useSetRecoilState(withPoint);
  const setCouponCount = useSetRecoilState(withCouponCount);
  const isEventMall = useSetRecoilState(getIsEventMall);
  const isPointUse = useSetRecoilState(getIsPointUse);
  const isCouponMenuUse = useSetRecoilState(getIsCouponMenuUse);

  useEffect(() => {
    (async () => {
      const { result } = await orderApi.getCartCount();
      setCartBadgeCount(result);
      if(isEventMall) {
        if(isPointUse) {
          const { result } = await myAssetPointApi.summary();
          setPoint(result.filter(({assetResourceDivisionCode})=> assetResourceDivisionCode === '24')[0]);
        }
        if(isCouponMenuUse) {
          const { result : result1 } = await couponApi.getMyCouponListCount();
          setCouponCount(result1);
        }
      }
    })();
  }, [setCartBadgeCount, setPoint,setCouponCount]);

  return <>{children}</>;
};

export default UserInfoProvider;

UserInfoProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
